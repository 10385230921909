var exports = {};

exports = function (hljs) {
  var COMMENT = hljs.COMMENT(/^\s*@?rem\b/, /$/, {
    relevance: 10
  });
  var LABEL = {
    className: "symbol",
    begin: "^\\s*[A-Za-z._?][A-Za-z0-9_$#@~.?]*(:|\\s+label)",
    relevance: 0
  };
  return {
    aliases: ["bat", "cmd"],
    case_insensitive: true,
    illegal: /\/\*/,
    keywords: {
      keyword: "if else goto for in do call exit not exist errorlevel defined " + "equ neq lss leq gtr geq",
      built_in: "prn nul lpt3 lpt2 lpt1 con com4 com3 com2 com1 aux " + "shift cd dir echo setlocal endlocal set pause copy " + "append assoc at attrib break cacls cd chcp chdir chkdsk chkntfs cls cmd color " + "comp compact convert date dir diskcomp diskcopy doskey erase fs " + "find findstr format ftype graftabl help keyb label md mkdir mode more move path " + "pause print popd pushd promt rd recover rem rename replace restore rmdir shift" + "sort start subst time title tree type ver verify vol " + // winutils
      "ping net ipconfig taskkill xcopy ren del"
    },
    contains: [{
      className: "variable",
      begin: /%%[^ ]|%[^ ]+?%|![^ ]+?!/
    }, {
      className: "function",
      begin: LABEL.begin,
      end: "goto:eof",
      contains: [hljs.inherit(hljs.TITLE_MODE, {
        begin: "([_a-zA-Z]\\w*\\.)*([_a-zA-Z]\\w*:)?[_a-zA-Z]\\w*"
      }), COMMENT]
    }, {
      className: "number",
      begin: "\\b\\d+",
      relevance: 0
    }, COMMENT]
  };
};

export default exports;